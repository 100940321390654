import React from 'react';

function NoticeCard({ imageSource, title, pageLink }) {
    return (
        <div className="relative max-w-xl mx-auto mt-2 group overflow-hidden rounded-md shadow-lg transition-transform duration-300 ease-in-out transform hover:shadow-xl cursor-pointer">
            <a href={pageLink}>
                <img className="h-64 w-full object-cover rounded-md" src={imageSource} alt={title} />

                <div className="absolute inset-0 rounded-md bg-gray-800 opacity-60 group-hover:opacity-40 transition-opacity duration-300 ease-in-out"></div>

                <div className="absolute inset-0 flex items-center justify-center">
                    <h2 className="text-white font-bold">{title}</h2>
                </div>
            </a>
        </div>
    );
}

export default NoticeCard;