import React, { useState } from 'react';
import { Stepper, Step, StepLabel, Typography } from '@mui/material';
import SuccessMessage from './SuccessMessage';

const steps = [
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    ''
];

const StepContent = ({ step, formData, handleChange, handleFileUpload }) => {
    // Same switch case as before
    switch (step) {
        // step1
        case 0:
            return (
                <div className="space-y-4">
                    <h5 className='underline underline-offset-4 decoration-sky-400 decoration-2'>Personal Data</h5>

                    <div className="mt-8 grid grid-cols-6 gap-6">
                        <div className='col-span-6 sm:col-span-3'>
                            <label className="block text-sm font-medium text-gray-700 fw-bold" htmlFor='name'>Full Name</label>
                            <input
                                id="name"
                                placeholder="John Doe"
                                value={formData.name}
                                onChange={handleChange('name')}
                                className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
                            />
                        </div>

                        <div className='col-span-6 sm:col-span-3'>
                            <label className="block text-sm font-medium text-gray-700 fw-bold" htmlFor='phonee'>Phone Number</label>
                            <input
                                id="phone"
                                placeholder="0700000000"
                                value={formData.phone}
                                onChange={handleChange('phone')}
                                className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
                            />
                        </div>

                        <div className='col-span-6 sm:col-span-3'>
                            <label className="block text-sm font-medium text-gray-700 fw-bold" htmlFor='email'>Email Address</label>
                            <input
                                id="email"
                                placeholder="johndoe@gmail.com"
                                value={formData.email}
                                onChange={handleChange('email')}
                                className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
                            />
                        </div>

                        <div className='col-span-6 sm:col-span-3'>
                            <label className="block text-sm font-medium text-gray-700 fw-bold" htmlFor='idNumber'>ID Number</label>
                            <input
                                id="idNumber"
                                placeholder="00000000"
                                value={formData.idNumber}
                                max={8}
                                onChange={handleChange('idNumber')}
                                className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
                            />
                        </div>

                        <div className='col-span-6 sm:col-span-3'>
                            <label className="block text-sm font-medium text-gray-700 fw-bold" htmlFor='dob'>Date of Birth</label>
                            <input
                                type='date'
                                id="dob"
                                placeholder=""
                                value={formData.dob}
                                onChange={handleChange('dob')}
                                className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
                            />
                        </div>

                        <div className='col-span-6 sm:col-span-3'>
                            <label className="block text-sm font-medium text-gray-700 fw-bold" htmlFor='physicalAddress'>Physical Address</label>
                            <input
                                id="physicalAddress"
                                placeholder="Physical Address"
                                value={formData.physicalAddress}
                                onChange={handleChange('physicalAddress')}
                                className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
                            />
                        </div>
                    </div>
                </div>
            );

        // step2
        case 1:
            return (
                <div className='space-y-4'>
                    <h5 className='underline underline-offset-4 decoration-sky-400 decoration-2'>Form A</h5>
                    <label className="block">
                        <small>Upload Form A</small>
                        <input
                            id="formA"
                            type="file"
                            onChange={handleFileUpload('formA')}
                            className="block w-full text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100 mt-2"
                        />
                    </label>
                </div>
            );

        //   step3
        case 2:
            return (
                <div className="space-y-4">
                    <h5 className='underline underline-offset-4 decoration-sky-400 decoration-2'>Education Data</h5>

                    <div className="mt-8 grid grid-cols-6 gap-6">
                        <div className="col-span-6 sm:col-span-3">
                            <label className="block text-sm font-medium text-gray-700 fw-bold" htmlFor='university'>Institution</label>

                            <input
                                id="university"
                                placeholder="Technical University of Kenya"
                                value={formData.university}
                                onChange={handleChange('university')}
                                className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
                            />
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                            <label className="block text-sm font-medium text-gray-700 fw-bold" htmlFor='courseOfStudy'>Course Name</label>

                            <input
                                id="courseOfStudy"
                                placeholder="Geospatial Engineering"
                                value={formData.courseOfStudy}
                                onChange={handleChange('courseOfStudy')}
                                className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
                            />
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                            <label className="block text-sm font-medium text-gray-700 fw-bold" htmlFor='levelOfQualification'>Level of Qualification</label>

                            <input
                                id="levelOfQualification"
                                placeholder="Bachelors"
                                value={formData.levelOfQualification}
                                onChange={handleChange('levelOfQualification')}
                                className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
                            />
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                            <label className="block text-sm font-medium text-gray-700 fw-bold" htmlFor='examiningBody'>Examining Body</label>

                            <input
                                id="examiningBody"
                                placeholder="Institution, KNEC, ...."
                                value={formData.examiningBody}
                                onChange={handleChange('examiningBody')}
                                className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
                            />
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                            <label className="block text-sm font-medium text-gray-700 fw-bold" htmlFor='startingYear'>Starting Year</label>

                            <input
                                type='date'
                                id="startingYear"
                                value={formData.startingYear}
                                onChange={handleChange('startingYear')}
                                className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
                            />
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                            <label className="block text-sm font-medium text-gray-700 fw-bold" htmlFor='endingYear'>Full Name</label>

                            <input
                                type='date'
                                id="endingYear"
                                value={formData.endingYear}
                                onChange={handleChange('endingYear')}
                                className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
                            />
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                            <label className="block text-sm font-medium text-gray-700 fw-bold" htmlFor='yearsOfPracticalPractice'>Years of Practice</label>

                            <input
                                id="yearsOfPracticalPractice"
                                placeholder="Years of Practical Practice"
                                value={formData.yearsOfPracticalPractice}
                                onChange={handleChange('yearsOfPracticalPractice')}
                                className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
                            />
                        </div>
                    </div>
                </div>
            );

        //   step4
        case 3:
            return (
                <div className="space-y-4">
                    <h5 className='underline underline-offset-4 decoration-sky-400 decoration-2'>ISK Membership Number</h5>
                    <div className="mt-8 grid grid-cols-6 gap-6">
                        <div className="col-span-6 sm:col-span-3">
                            <label className="block text-sm font-medium text-gray-700" htmlFor='iskMembershipNumber'>ISK Membership Number</label>

                            <input
                                id="iskMembershipNumber"
                                placeholder="000000"
                                value={formData.iskMembershipNumber}
                                onChange={handleChange('iskMembershipNumber')}
                                className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
                            />
                        </div>
                    </div>
                </div>
            );

        //   step5
        case 4:
            return (
                <div className="space-y-4">
                    <h5 className='underline underline-offset-4 decoration-sky-400 decoration-2'>ISK and Land Law Certificates</h5>

                    <label className="block">
                        <small className='fw-bold'>#1. Upload Board Land Law Exam Pass</small>
                        <input
                            id="boardLandLawExamPass"
                            type="file"
                            onChange={handleFileUpload('boardLandLawExamPass')}
                            className="block w-full text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100 mt-2"
                        />
                    </label>

                    <label className="block">
                        <small className='fw-bold'>#2. Upload ISK Membership Certificate</small>
                        <input
                            id="iskMembershipCertificate"
                            type="file"
                            onChange={handleFileUpload('iskMembershipCertificate')}
                            className="block w-full text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100 mt-2"
                        />
                    </label>

                    <label className="block">
                        <small className='fw-bold'>#3. Upload ISK Certificate of Good Standing</small>
                        <input
                            id="iskCertificateOfGoodStanding"
                            type="file"
                            onChange={handleFileUpload('iskCertificateOfGoodStanding')}
                            className="block w-full text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100 mt-2"
                        />
                    </label>
                </div>
            );

        //   step6
        case 5:
            return (
                <div className="space-y-4">
                    <h5 className='underline underline-offset-4 decoration-sky-400 decoration-2'>Education Certificates</h5>

                    <label className="block">
                        <small className='fw-bold'>PHD Certificate</small>
                        <input
                            id="phdCertificate"
                            type="file"
                            onChange={handleFileUpload('phdCertificate')}
                            className="block w-full text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100 mt-2"
                        />
                    </label>

                    <label className="block">
                        <small className='fw-bold'>Masters Certificate</small>
                        <input
                            id="mastersCertificate"
                            type="file"

                            onChange={handleFileUpload('mastersCertificate')}
                            className="block w-full text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100 mt-2"
                        />
                    </label>

                    <label className="block">
                        <small className='fw-bold'>Bachelors Certificate</small>
                        <input
                            id="bachelorsCertificate"
                            type="file"
                            onChange={handleFileUpload('bachelorsCertificate')}
                            className="block w-full text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100 mt-2"
                        />
                    </label>

                    <label className="block">
                        <small className='fw-bold'>Higher National Diploma</small>
                        <input
                            id="higherNationalDiploma"
                            type="file"
                            onChange={handleFileUpload('higherNationalDiploma')}
                            className="block w-full text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100 mt-2"
                        />
                    </label>

                    <label className="block">
                        <small className='fw-bold'>Diploma</small>
                        <input
                            id="diploma"
                            type="file"
                            onChange={handleFileUpload('diploma')}
                            className="block w-full text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100 mt-2"
                        />
                    </label>

                    <label className="block">
                        <small className='fw-bold'>KCSE</small>
                        <input
                            id="kcse"
                            type="file"
                            onChange={handleFileUpload('kcse')}
                            className="block w-full text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100 mt-2"
                        />
                    </label>
                </div>
            );

        //   step7
        case 6:
            return (
                <div className="space-y-4">
                    <h5 className='underline underline-offset-4 decoration-sky-400 decoration-2'>Survey Details</h5>

                    <div className='my-3'>
                        <span className='fw-bold'>Cadastral Survey</span>

                        <div className="mt-2 grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-3">
                                <input
                                    id="cadastralFolioReferenceNumber"
                                    placeholder="Folio Reference Number"
                                    value={formData.cadastralFolioReferenceNumber}
                                    onChange={handleChange('cadastralFolioReferenceNumber')}
                                    className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
                                />
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                                <input
                                    id="cadastralComputationFileNumber"
                                    placeholder="Computation File Number"
                                    value={formData.cadastralComputationFileNumber}
                                    onChange={handleChange('cadastralComputationFileNumber')}
                                    className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
                                />
                            </div>
                        </div>
                    </div>

                    <div className='my-3'>
                        <span className='fw-bold'>Farm Survey</span>

                        <div className="mt-2 grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-3">
                                <input
                                    id="farmFolioReferenceNumber"
                                    placeholder="Folio Reference Number"
                                    value={formData.farmFolioReferenceNumber}
                                    onChange={handleChange('farmFolioReferenceNumber')}
                                    className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
                                />
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                                <input
                                    id="farmComputationFileNumber"
                                    placeholder="Computation File Number"
                                    value={formData.farmComputationFileNumber}
                                    onChange={handleChange('farmComputationFileNumber')}
                                    className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
                                />
                            </div>
                        </div>
                    </div>

                    <div className='my-3'>
                        <span className='fw-bold'>Control Survey</span>

                        <div className="mt-2 grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-3">
                                <input
                                    id="controlFolioReferenceNumber"
                                    placeholder="Folio Reference Number"
                                    value={formData.controlFolioReferenceNumber}
                                    onChange={handleChange('controlFolioReferenceNumber')}
                                    className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
                                />
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                                <input
                                    id="controlComputationFileNumber"
                                    placeholder="Computation File Number"
                                    value={formData.controlComputationFileNumber}
                                    onChange={handleChange('controlComputationFileNumber')}
                                    className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
                                />
                            </div>
                        </div>
                    </div>

                    <div className='my-3'>
                        <span className='fw-bold'>Curvilinear Survey</span>

                        <div className="mt-2 grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-3">
                                <input
                                    id="curvilinearFolioReferenceNumber"
                                    placeholder="Folio Reference Number"
                                    value={formData.curvilinearFolioReferenceNumber}
                                    onChange={handleChange('curvilinearFolioReferenceNumber')}
                                    className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
                                />
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                                <input
                                    id="curvilinearComputationFileNumber"
                                    placeholder="Computation File Number"
                                    value={formData.curvilinearComputationFileNumber}
                                    onChange={handleChange('curvilinearComputationFileNumber')}
                                    className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
                                />
                            </div>
                        </div>
                    </div>

                    <div className='my-3'>
                        <span className='fw-bold'>Topographical Survey</span>

                        <div className="mt-2 grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-3">
                                <input
                                    id="topographicalProjectName"
                                    placeholder="Project Name"
                                    value={formData.topographicalProjectName}
                                    onChange={handleChange('topographicalProjectName')}
                                    className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
                                />
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                                <input
                                    id="topographicalAcreage"
                                    placeholder="Acreage"
                                    value={formData.topographicalAcreage}
                                    onChange={handleChange('topographicalAcreage')}
                                    className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
                                />
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                                <input
                                    id="topographicalFolioReferenceNumber"
                                    placeholder="Folio Reference Number"
                                    value={formData.topographicalFolioReferenceNumber}
                                    onChange={handleChange('topographicalFolioReferenceNumber')}
                                    className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            );

        //  step8
        case 7:
            return (
                <div className="space-y-4">
                    <h5 className='underline underline-offset-4 decoration-sky-400 decoration-2'>Any Additional Information</h5>
                    <small className='text-red-600 fw-bold'>* Not Mandatory</small>

                    <div className="mt-2 gap-6">
                        <div className="col-span-6 sm:col-span-3 bg-gray-200 p-3 my-3">
                            <label className="block text-sm font-medium text-gray-700 fw-bold" htmlFor='articlesPublication'>Articles/Publications</label>

                            <textarea
                                id="articlesPublications"
                                placeholder="List or Seperate with Commas"
                                rows={4}
                                value={formData.articlesPublications}
                                onChange={handleChange('articlesPublications')}
                                className="mt-1 w-full border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
                            />
                        </div>

                        <div className="col-span-6 sm:col-span-3 bg-gray-200 p-3 my-3">
                            <label className="block text-sm font-medium text-gray-700 fw-bold" htmlFor='honorsDistinction'>Honors/ Distinctions</label>

                            <textarea
                                id="honorsDistinctions"
                                placeholder="List or Seperate with Commas"
                                rows={4}
                                value={formData.honorsDistinctions}
                                onChange={handleChange('honorsDistinctions')}
                                className="mt-1 w-full border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
                            />
                        </div>
                    </div>
                </div>
            );

        //   step9
        case 8:
            return (
                <div className="space-y-4">
                    <h5 className='underline underline-offset-4 decoration-sky-400 decoration-2'>Declaration</h5>

                    <div className='leading-8'>
                        I, <span className='fw-bold underline underline-offset-4 decoration-2 link-underline-opacity-8 decoration-sky-500 uppercase'>{formData.name || 'John Doe'}</span>, of ID Number <span className='fw-bold underline underline-offset-4 decoration-2 link-underline-opacity-8 decoration-sky-500'>{formData.idNumber || '00000000'}</span> solemnly and sincerely declare that I am a graduate of <span className='fw-bold underline underline-offset-4 decoration-2 link-underline-opacity-8 decoration-sky-500 uppercase'>{formData.university || 'XXXX'}</span> university and have taken a degree in <span className='fw-bold underline underline-offset-4 decoration-2 link-underline-opacity-8 decoration-sky-500 uppercase'>{formData.courseOfStudy || 'Geospatial'}</span> and that I have pursued the course of study and had practical experience in Land Surveying as set out in the summary.
                    </div>

                    <div className="my-2">
                        <span className="leading-8">
                            I confirm I have met the necessary requirements and therein attached them. I acknowledge that any statement and documentation included in this application which is known by me to be false shall invalidate this application in its entirety. I have read the Survey Act (Cap 299) and its Regulations, I am aware of the penalties stipulated in the Act and the Regulations that if licensed, I shall be bound by the Act and its Regulations and any amendments thereto.
                        </span>
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                        <label className="block text-sm font-medium text-gray-700 fw-bold">MPESA Code</label>
                        <input
                            type="text"
                            className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
                            id="mpesaCode"
                            value={formData.mpesaCode}
                            onChange={handleChange('mpesaCode')}
                            placeholder="Enter MPESA Code"
                        />
                    </div>
                </div>
            );
        default:
            return 'Unknown step';
    }
};

const Workflow = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        idNumber: '',
        dob: '',
        formA: null,
        university: '',
        courseOfStudy: '',
        levelOfQualification: '',
        examiningBody: '',
        startingYear: '',
        endingYear: '',
        yearsOfPracticalPractice: '',
        iskMembershipNumber: '',
        boardLandLawExamPass: null,
        iskMembershipCertificate: null,
        iskCertificateOfGoodStanding: null,
        phdCertificate: null,
        mastersCertificate: null,
        bachelorsCertificate: null,
        higherNationalDiploma: null,
        diploma: null,
        kcse: null,
        cadastralFolioReferenceNumber: '',
        cadastralComputationFileNumber: '',
        farmFolioReferenceNumber: '',
        farmComputationFileNumber: '',
        controlFolioReferenceNumber: '',
        controlComputationFileNumber: '',
        curvilinearFolioReferenceNumber: '',
        curvilinearComputationFileNumber: '',
        topographicalProjectName: '',
        topographicalAcreage: '',
        topographicalFolioReferenceNumber: '',
        articlesPublications: '',
        honorsDistinctions: ''
    });

    const [submissionSuccess, setSubmissionSuccess] = useState(false);

    const handleChange = (key) => (event) => {
        setFormData({ ...formData, [key]: event.target.value });
    };

    const handleFileUpload = (key) => (event) => {
        setFormData({ ...formData, [key]: event.target.files[0] });
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSubmit = () => {
        // Simulate sending data to server
        console.log('Form Data:', formData);
        // Send data to server (e.g., using fetch or axios)
        // fetch('/api/submit', { method: 'POST', body: JSON.stringify(formData) });
        setSubmissionSuccess(true);
        setTimeout(() => {
            setSubmissionSuccess(false);
            setActiveStep(0);
            setFormData({
                name: '',
                phone: '',
                email: '',
                idNumber: '',
                dob: '',
                formA: null,
                university: '',
                courseOfStudy: '',
                levelOfQualification: '',
                examiningBody: '',
                startingYear: '',
                endingYear: '',
                yearsOfPracticalPractice: '',
                iskMembershipNumber: '',
                boardLandLawExamPass: null,
                iskMembershipCertificate: null,
                iskCertificateOfGoodStanding: null,
                phdCertificate: null,
                mastersCertificate: null,
                bachelorsCertificate: null,
                higherNationalDiploma: null,
                diploma: null,
                kcse: null,
                cadastralFolioReferenceNumber: '',
                cadastralComputationFileNumber: '',
                farmFolioReferenceNumber: '',
                farmComputationFileNumber: '',
                controlFolioReferenceNumber: '',
                controlComputationFileNumber: '',
                curvilinearFolioReferenceNumber: '',
                curvilinearComputationFileNumber: '',
                topographicalProjectName: '',
                topographicalAcreage: '',
                topographicalFolioReferenceNumber: '',
                articlesPublications: '',
                honorsDistinctions: ''
            });
        }, 10000);
    };

    return (
        <div className="container mx-auto p-4">
            <Stepper activeStep={activeStep} className="mb-4">
                {steps.map((label, index) => (
                    <Step key={index}>
                        <StepLabel>
                            {index === activeStep && <span>{label}</span>}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div>
                {submissionSuccess ? (
                    <SuccessMessage />
                ) : activeStep === steps.length ? (
                    <div>
                        <Typography>All steps completed</Typography>
                        <button onClick={handleBack} className='button'>
                            <span className="button_lg">
                                <span className="button_sl"></span>
                                <span className="button_text">Back</span>
                            </span>
                        </button>
                    </div>
                ) : (
                    <div>
                        <StepContent
                            step={activeStep}
                            formData={formData}
                            handleChange={handleChange}
                            handleFileUpload={handleFileUpload}
                        />
                        <div className="flex gap-3 mt-4">
                            <button disabled={activeStep === 0} onClick={handleBack} className='button'>
                                <span className="button_lg">
                                    <span className="button_sl"></span>
                                    <span className="button_text">Back</span>
                                </span>
                            </button>

                            <button
                                color="primary"
                                onClick={activeStep === steps.length - 1 ? handleSubmit : handleNext}
                                className='button'
                            >
                                <span className="button_lg">
                                    <span className="button_sl"></span>
                                    <span className="button_text">{activeStep === steps.length - 1 ? 'Finish' : 'Next'}</span>
                                </span>
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Workflow;