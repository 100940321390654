import React from 'react';
import List from '../list';
import LandLawWorkflow from '../WorkFlows/LandLawWorkflow';

function LandLaw() {
  return (
    <div className="container my-10">
      <h1 className="font-black leading-none text-gray-800 uppercase dark:text-white text-uppercase text-center my-10">REQUIREMENTS TO SIT LAND LAW EXAMINATION</h1>

      <p className="text-center">
        Eligibility applies to candidates in accordance with Section 11 of the Survey Act (Cap. 299), together with those who have passed the Final Part I of the East African Land Survey Examination or would be exempted there from (proof of pass or exemption is required).
      </p>

      <div className="flex flex-col items-start">
        <List description="Duly filled Form A of the Survey Act. (Must have had at least one year experience in Land Surveying)" />

        <List description="Copy of National Identity Card (ID)" />

        <List description="Copy of Relevant Certified Academic Certificates" />

        <List description="The examination fee of Five Thousand Kenya Shillings (Ksh. 5,000.00) payable to Land Surveyors’ Board through ECitizen" />

        <List description="Application letter must be addressed to:" />

        <ul className="mx-12">
          <li className="text-sm text-gray-500">Secretary Land Surveyors Board</li>
          <li className="text-sm text-gray-500">P.O. Box 30046-00100, NAIROBI</li>
        </ul>
      </div>

      <div className="my-3 border-t">
        <LandLawWorkflow />
      </div>
    </div>
  )
}

export default LandLaw;