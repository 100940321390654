import React from 'react';

function MemberCard({ position, name, role, profilePic }) {
    return (
        <div className="flex flex-col justify-center items-center gap-1" id="member_Card">
            <img
                alt={position}
                src={profilePic}
                className="rounded-full"
                style={{ width: "200px" }}
            />

            <div className="text-center h-100">
                <small className="text-black text-uppercase italic">{position}</small>
                <p className="font-medium text-gray-900">
                    {name}
                    <br />
                    <small className="text-gray-500">{role}</small>
                </p>
            </div>
        </div>
    )
}

export default MemberCard;
