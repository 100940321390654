import React, { useState, useEffect } from "react";

const backendUrl = "https://portal.lsb.go.ke/account/licensed/";

function CurrentRegister() {
  const [surveyors, setSurveyors] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(backendUrl);
        const data = await response.json();
        setSurveyors(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to fetch data');
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    const results = surveyors.filter((surveyor) =>
      surveyor.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(results);
  }, [searchTerm, surveyors]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const renderTableHeaders = () => (
    <thead className="uppercase">
      <tr>
        <th scope="col" className="py-2 px-3 bg-black text-white">
          SNo.
        </th>
        <th scope="col" className="bg-black text-white">
          NAME
        </th>
        <th scope="col" className="bg-black text-white">
          LICENCE
        </th>
        <th scope="col" className="px-3 bg-black text-white">
          ORGANIZATION
        </th>
        <th scope="col" className="bg-black text-white">
          ADDRESS
        </th>
        <th scope="col" className="bg-black text-white">
          STATUS
        </th>
      </tr>
    </thead>
  );

  const renderTableRows = (data) => (
    <tbody>
      {data.map((surveyor, index) => (
        <tr
          key={index}
          className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
        >
          <td className="py-2 px-3">{surveyor.licensed_id}</td>
          <td className="">{surveyor.name}</td>
          <td className="">{surveyor.license_no}</td>
          <td className="px-3">{surveyor.firm}</td>
          <td className="">{surveyor.postal_address}</td>
          <td className="capitalize">{surveyor.status}</td>
        </tr>
      ))}
    </tbody>
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <div className="relative overflow-x-auto">
        <label
          htmlFor="licensedSurveyor"
          className="relative block overflow-hidden border-b border-gray-200 bg-transparent pt-3 focus-within:border-blue-600"
        >
          <input
            type="text"
            placeholder=""
            className="peer h-12 w-full border-none bg-gray-200 px-4 placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 rounded-t-lg fw-bold"
            value={searchTerm}
            onChange={handleSearchChange}
          />

          <span
            className="absolute start-0 top-2 -translate-y-1/2 text-xs text-gray-800 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-2 peer-focus:text-xs px-4"
          >
            Search by name...
          </span>
        </label>
      </div>

      <div className="overflow-x-auto mt-2">
        <table className="w-full text-sm text-left rtl:text-right table table-striped">
          {renderTableHeaders()}
          {renderTableRows(searchTerm ? searchResults : surveyors)}
        </table>
      </div>
    </div>
  );
}

export default CurrentRegister;