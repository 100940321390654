import React, { useState, useEffect } from "react";
import "./complaints.css";
import ComplaintPic from "./complaint.webp";
import List from "../list";

function Complaints() {
    const [names, setNames] = useState([]);
    const [selectedName, setSelectedName] = useState("");
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const [uploadedDocuments, setUploadedDocuments] = useState([]);
    const [uploadError, setUploadError] = useState("");

    useEffect(() => {
        // Fetch data from the server
        fetch("http://localhost:5959/account/licensed/")
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then((data) => {
                // Extract only the names from the response
                const extractedNames = data.map((item) => item.name);
                setNames(extractedNames);
            })
            .catch((error) => console.error("Error fetching data:", error));
    }, []);

    useEffect(() => {
        // Check if a name is selected
        setIsSubmitDisabled(selectedName === "");
    }, [selectedName]);

    const handleNameChange = (event) => {
        setSelectedName(event.target.value);
    };

    const handleFileChange = (event) => {
        const files = event.target.files;
        const allowedTypes = [
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "image/jpeg",
            "application/pdf",
        ];

        const selectedFiles = Array.from(files);

        const invalidFiles = selectedFiles.filter(
            (file) => !allowedTypes.includes(file.type)
        );

        if (invalidFiles.length > 0) {
            setUploadError(
                "Invalid file type. Please select only DOCX, JPG, or PDF files."
            );
        } else {
            setUploadedDocuments([...uploadedDocuments, ...selectedFiles]);
            setUploadError("");
        }
    };

    const removeFile = (index) => {
        const updatedFiles = [...uploadedDocuments];
        updatedFiles.splice(index, 1);
        setUploadedDocuments(updatedFiles);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle form submission
    };

    return (
        <div className="">
            <div className="hero">
                <img
                    className="h-96 w-full object-cover object-center"
                    src={ComplaintPic}
                    alt="Nature Scene"
                />
            </div>

            <div className="container mt-4">
                <h1 className="flex flex-col font-black leading-none text-gray-800 uppercase dark:text-white text-center mb-3">
                    submit a complaint
                </h1>

                <div>
                    <p className="font-black leading-none text-gray-800 uppercase dark:text-white">
                        Complaints Handling Procedure
                    </p>

                    <p>
                        Welcome to the Kenya Land Surveyors Board (LSB). We are committed to
                        upholding the highest standards of professionalism and integrity in
                        the field of land surveying. As part of our dedication to serving
                        the public interest, we have established a robust complaints
                        handling procedure in accordance with the Survey Act Cap 299.
                    </p>

                    <p className="font-black leading-none text-gray-800 uppercase dark:text-white">
                        Submitting a Complaint
                    </p>

                    <p>
                        If you have any concerns or complaints related to the conduct or
                        services provided by a registered land surveyor, we encourage you to
                        bring them to our attention. You can submit your complaint through
                        the following channels:
                    </p>

                    <div className="flex flex-col items-start mb-4">
                        <List
                            requirement="Online Complaint Form"
                            description="Fill out our online complaint form below, providing detailed information about the nature of your complaint and any supporting documentation you may have."
                        />

                        <List
                            requirement="Postal Mail"
                            description="You can also submit your complaint by mail to the following address:"
                        />

                        <small className="fw-bold mx-16 text-center text-gray-500">
                            Land Surveyors Board <br /> P.O. Box 30046-00100 <br /> Nairobi,
                            Kenya
                        </small>
                    </div>

                    <div className="complaints_rules mt-5">
                        <p className="font-black leading-none text-gray-800 uppercase dark:text-white">
                            Procedure
                        </p>

                        <List
                            requirement="Acknowledgement"
                            description="We will acknowledge receipt of your complaint promptly and provide you with a reference number for tracking purposes"
                        />

                        <List
                            requirement="Investigation"
                            description="Our team will thoroughly investigate the allegations raised in your complaint, gathering relevant information and evidence."
                        />

                        <List
                            requirement="Resolution"
                            description="Based on the findings of our investigation, we will take appropriate action to address the issues raised in your complaint. This may include disciplinary measures against the registered land surveyor if misconduct is found."
                        />

                        <List
                            requirement="Communication"
                            description="Throughout the process, we will keep you informed of the progress of your complaint and any outcomes or decisions reached."
                        />
                    </div>
                </div>

                <div className="container mt-2">
                    <p className="font-black leading-none text-gray-800 uppercase dark:text-white">
                        Confidentiality & Fairness
                    </p>

                    <p>
                        We treat all complaints with the utmost confidentiality and ensure
                        that the complaints handling process is fair and transparent. We are
                        committed to protecting the rights of both complainants and
                        respondents, and we strive to reach a resolution that is equitable
                        and just.
                    </p>

                    <form
                        className="mt-4 border-1 p-3 rounded-lg border-secondary"
                        onSubmit={handleSubmit}
                    >
                        <div className="mt-1">
                            <p className="text-red-800 uppercase sm:text-1xl fw-bold text-center">
                                ACCUSED *
                            </p>
                        </div>

                        <div>
                            <select value={selectedName} onChange={handleNameChange}>
                                <option value="">Select A Licensed Surveyor...</option>
                                {names.map((name, index) => (
                                    <option key={index} value={name}>
                                        {name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="mt-3">
                            <textarea
                                className="flex-1 w-full px-4 py-2 text-base text-gray-700 placeholder-gray-400 bg-white border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                                id="complaint"
                                placeholder="Nature/ Details & Location of Complaint"
                                name="comment"
                                rows="5"
                                cols="40"
                            ></textarea>
                        </div>

                        <div className="mt-3">
                            <p className="text-red-800 uppercase sm:text-1xl fw-bold text-center">
                                COMPLAINANT *
                            </p>
                        </div>

                        <div className="row">
                            <div className="col">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Firstname"
                                    aria-label="First name"
                                />
                            </div>
                            <div className="col">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Lastname"
                                    aria-label="Last name"
                                />
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col">
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Phonenumber"
                                    aria-label="First name"
                                />
                            </div>
                            <div className="col">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Email"
                                    aria-label="Last name"
                                />
                            </div>
                        </div>

                        <div className="input-group mt-2">
                            <span className="input-group-text fw-bold">
                                Your County/ SubCounty
                            </span>
                            <input type="text" aria-label="county" className="form-control" />
                            <input
                                type="text"
                                aria-label="subcounty"
                                className="form-control"
                            />
                        </div>

                        <div className="mt-3">
                            <p className="text-red-800 uppercase fw-bold text-center">
                                SUPPORTING EVIDENCE *
                            </p>
                        </div>

                        <div className="">
                            <div className="flex items-center justify-center w-full">
                                <label
                                    htmlFor="dropzone-file"
                                    className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                                >
                                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                        <svg
                                            className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 20 16"
                                        >
                                            <path
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                            />
                                        </svg>
                                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                            <span className="font-semibold">Click to upload</span> or
                                            drag and drop
                                        </p>
                                        <p className="text-xs text-gray-500 dark:text-gray-400">
                                            DOCX, JPG or PDF
                                        </p>
                                        <small className="text-gray-500 dark:text-gray-400">
                                            Ensure any image uploaded is clear and indicates your
                                            complaint & maximum of 5 Files
                                        </small>
                                    </div>
                                    <input
                                        id="dropzone-file"
                                        type="file"
                                        className="hidden"
                                        accept=".docx,.jpg,.pdf"
                                        onChange={handleFileChange}
                                        multiple
                                    />
                                </label>
                            </div>
                            {uploadError && (
                                <p className="text-red-500 text-sm mt-2">{uploadError}</p>
                            )}
                            {uploadedDocuments.length > 0 && (
                                <ul className="mt-2">
                                    <small className="underline fw-bold">Uploaded Documents:</small>
                                    {uploadedDocuments.map((file, index) => (
                                        <li key={index}>
                                            {file.name}
                                            <button
                                                onClick={() => removeFile(index)}
                                                className="mx-2 text-red-600 fw-bold"
                                            >
                                                Remove
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>

                        <div className="form-check mt-3">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckDefault"
                            />
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                <strong>I confirm that:</strong> I have read, and I understand
                                the complaints procedure and that the information herein
                                submitted is factual.
                            </label>
                        </div>

                        <button className="button mt-4" type="submit" disabled={isSubmitDisabled}>
                            <span className="button_lg">
                                <span className="button_sl"></span>
                                <span className="button_text">submit</span>
                            </span>
                        </button>
                    </form>
                </div>

                <div className="mt-5 flex flex-col justify-center items-center text-center">
                    <p>
                        Thank you for your cooperation in helping us maintain the integrity
                        and professionalism of the land surveying profession in Kenya.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Complaints;