import React, { useState } from 'react';

const LandLawWorkflow = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [formData, setFormData] = useState({
        // Define initial form data structure
        survey: '',
        cadastralSurvey: '',
        topographicalSurvey: '',
        controlSurvey: '',
        declarationForms: [],
        landLawExamination: '',
        iskMembership: '',
        certificateOfParticipation: '',
        uptoDateReceipt: '',
        applicationForm: '',
        applicationLetter: '',
        mpesaCode: ''
    });

    const totalSteps = 4;

    const handleNext = () => {
        setActiveStep((prevStep) => Math.min(prevStep + 1, totalSteps - 1));
    };

    const handleBack = () => {
        setActiveStep((prevStep) => Math.max(prevStep - 1, 0));
    };

    const handleSubmit = () => {
        // Submit action with formData
        console.log('Form submitted with data:', formData);
        alert('Form submitted!');
    };

    const handleFileChange = (event, key) => {
        // Handle file input change and update formData
        setFormData((prevData) => ({
            ...prevData,
            [key]: event.target.files[0]
        }));
    };

    const handleCheckboxChange = (event) => {
        // Handle checkbox change and update formData
        setFormData((prevData) => ({
            ...prevData,
            [event.target.name]: event.target.checked
        }));
    };

    return (
        <div className="w-full my-1">
            <p className="fw-bold">APPLY TO SIT LAND LAW</p>

            <div className="p-1">
                <small className="my-2 italic fw-bold text-gray-500">
                    Step {activeStep + 1} of {totalSteps}
                </small>

                {activeStep === 0 && <StepContent>
                    <p className="fw-bold">Filled Declaration Form A</p>
                    <small>Allowable Formats [JPEG, PNG, PDF]:</small>

                    <label class="block">
                        <input
                            type="file" className="block w-full text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100"
                            onChange={(event) => handleFileChange(event, 'someKey')}
                        />
                    </label>
                </StepContent>}

                {activeStep === 1 && <StepContent>
                    <p className="fw-bold">Identity Card</p>

                    <small>Allowable Formats [JPEG, PNG, PDF]:</small>

                    <label class="block">
                        <input
                            type="file" className="block w-full text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100"
                            onChange={(event) => handleFileChange(event, 'someKey')}
                        />
                    </label>
                </StepContent>}

                {activeStep === 2 && <StepContent>
                    <p className="fw-bold">Relevant Certified Academic Papers</p>

                    <small>Allowable Formats [JPEG, PNG, PDF]:</small>

                    <label class="block flex flex-col gap-2">
                        <input
                            type="file" className="block w-full text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100"
                            onChange={(event) => handleFileChange(event, 'someKey')}
                        />

                        <input
                            type="file" className="block w-full text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100"
                            onChange={(event) => handleFileChange(event, 'someKey')}
                        />

                        <input
                            type="file" className="block w-full text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100"
                            onChange={(event) => handleFileChange(event, 'someKey')}
                        />

                        <input
                            type="file" className="block w-full text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100"
                            onChange={(event) => handleFileChange(event, 'someKey')}
                        />

                        <input
                            type="file" className="block w-full text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100"
                            onChange={(event) => handleFileChange(event, 'someKey')}
                        />

                        <input
                            type="file" className="block w-full text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100"
                            onChange={(event) => handleFileChange(event, 'someKey')}
                        />
                    </label>
                </StepContent>}


                {activeStep === 3 && <StepContent>
                    <p className="fw-bold">Payment Proof and Declaration</p>

                    <div className="flex gap-3 items-center">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="formCheckDefault"
                            name="acceptanceCheckbox"
                            onChange={handleCheckboxChange}
                        />

                        <span className="ml-2 text-gray-700">I confirm I have met the necessary requirements and therein attached them. I acknowledge that any statement and documentation included in this application which is known by me to be false shall invalidate this application in its entirety. I have read the Survey Act (Cap 299) and its Regulations, I am aware of the penalties stipulated in the Act and the Regulations that if licensed, I shall be bound by the Act and its Regulations and any ammendments thereto.</span>
                    </div>

                    <input type="text" className="form-control mt-3" placeholder="M-PESA Code" aria-label="M-PESA Code" aria-describedby="input-group-right" />
                </StepContent>}

                <div className="flex gap-3 mt-4">
                    {activeStep > 0 && (
                        <button className="button mt-4" onClick={handleBack}>
                            <span className="button_lg">
                                <span className="button_sl"></span>
                                <span className="button_text">back</span>
                            </span>
                        </button>
                    )}
                    {activeStep < totalSteps - 1 && (
                        <button className="button mt-4" onClick={handleNext}>
                            <span className="button_lg">
                                <span className="button_sl"></span>
                                <span className="button_text">next</span>
                            </span>
                        </button>
                    )}
                    {activeStep === totalSteps - 1 && (
                        <button className="button mt-4" onClick={handleSubmit}>
                            <span className="button_lg">
                                <span className="button_sl"></span>
                                <span className="button_text">submit</span>
                            </span>
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

const StepContent = ({ children }) => {
    return <div>{children}</div>;
};

export default LandLawWorkflow;