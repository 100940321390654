import React from 'react';
import USER from './user.svg';

function PublicProfile() {
    return (
        <div className="grid grid-cols-1 gap-2 lg:grid-cols-3 lg:gap-8 mx-auto max-w-screen-xl px-4 py-8 sm:px-6 lg:px-8">
            <div className="flex flex-col justify-center items-center">
                <img src={USER} alt='PublicProfile' style={{ width: "calc(15em + 1vw)" }} />
                <h3 className='mt-2'>John Sidney Vaughan</h3>
            </div>

            <div className="lg:col-span-2 p-4">
                {/* general status */}
                <div className=''>
                    <p className='underline py-2 fw-bold uppercase text-4xl'>general data</p>
                    <table class="table table-hover">
                        <tbody>
                            <tr>
                                <th scope="row-span-1">License No.</th>
                                <td>95</td>
                            </tr>

                            <tr>
                                <th scope="row-span-1">Practising Status</th>
                                <td>INACTIVE</td>
                            </tr>

                            <tr>
                                <th scope="row">Practising Number (2024)</th>
                                <td>N/A</td>
                            </tr>

                            <tr>
                                <th scope="row">Phone</th>
                                <td>0700123456</td>
                            </tr>

                            <tr>
                                <th scope="row">Email</th>
                                <td>vaughan.js@gmail.com</td>
                            </tr>

                            <tr>
                                <th scope="row">Organization</th>
                                <td>J.S Vaughan, Licenced</td>
                            </tr>

                            <tr>
                                <th scope="row">Physical Address</th>
                                <td>Jacob</td>
                            </tr>

                            <tr>
                                <th scope="row">Postal Address</th>
                                <td>46, Naivasha</td>
                            </tr>

                            <tr>
                                <th scope="row">Assistants</th>
                                <td>10</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default PublicProfile;