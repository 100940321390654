import React from 'react';
import LocationPic from "./LocationPic.jpg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

function Contact() {
    return (
        <section>
            <div className="contact">
                <img
                    className="h-96 w-full object-cover object-center"
                    src={LocationPic}
                    alt="Location"
                    loading="lazy"
                />
            </div>

            <h1 className="text-center font-bold uppercase">get in touch</h1>

            <iframe
                title="Google Maps"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3298.0888817730593!2d36.855417073991916!3d-1.2494396355849173!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f1687d0000001%3A0x60dca9458a33a718!2sSurvey%20Of%20Kenya!5e1!3m2!1sen!2ske!4v1706396670538!5m2!1sen!2ske"
                width="98%"
                height="700px"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                className="mx-3 my-3"
            />

            <p className="text-center font-bold">
                Land Surveyors Board, <br />
                P.O. Box 30046-00100, <br />
                Nairobi, Kenya
            </p>

            <div className="flex justify-center gap-3 my-3">
                <div className="flex flex-col items-center gap-1">
                    <FontAwesomeIcon icon={faEnvelope} />
                    <small>info@lsb.go.ke</small>
                </div>

                <div className="flex flex-col items-center gap-1">
                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                    <small>Survey of Kenya</small>
                </div>
            </div>
        </section>
    )
}

export default Contact;
