import React from 'react';
import TrialImg from './trialSurvey.jpg';
import List from "../list";

function TrialSurvey() {
  return (
    <div>
      <div className="hero">
        <img
          className="h-96 w-full object-cover object-center"
          src={TrialImg}
          alt="trial survey"
        />
      </div>

      <div className="container mt-4 mb-4">
        <div>
          <h1 className="font-bold mb-4">Trial Survey Guidelines</h1>

          <div className="flex flex-col gap-2 justify-start items-start">
            <List description="Duly completed declaration forms, A & B of the third schedule of the Survey Act." />

            <List description="A pass in the Board's Land Law Examination" />

            <List description="Attach Certified Academic Certificates" />

            <List description="Full membership of Institution of Surveyors of Kenya, with a certificate of participation and up to date payment" />

            <List description="A duly completed application form of the Land Surveyors Board" />

            <List description="Application letter to the Board" />

            <List description="Application fees of KSh 15,000.00" />
          </div>
        </div>

        <div className="mt-5">
          <h1>Examination Requirements</h1>
          <p>The candidate shall qualify for a Ten (10) weeks Trial Survey if he/she has at least two (2) Years of approved experience.</p>

          <p>The candidate shall sit the following examinations:</p>

          <div className="flex flex-col gap-2 justify-start items-start">
            <List description="Viva Voce (Oral Examinations in Surveying)" />

            <List description="Carry out astronomic field determination of the following:" />
            <ul className="mx-12" style={{ listStyleType: 'square' }}>
              <li className="text-sm text-gray-500">Longitude</li>
              <li className="text-sm text-gray-500">Latitude</li>
              <li className="text-sm text-gray-500">Azimuth</li>
            </ul>
            <p className="text-gray-500 mx-16">at a specified station(s). Equipments to be used will be a Theodolite or Total Station of appropriate accuracy 1 arc second or better.</p>

            <List description="Carry out a triangulation control survey and densify four (4) controls in a given area. Densification methods to include traverses and to survey a specified area that covers a curvilinear boundary features like rivers. The control survey to include at least one baseline of 200 meters." />

            <List description="Cadastral Survey (10 plots) of a small township area with buildings in accordance to the Boards guidelines. Use of aerial photographs or satellite images may be allowed." />

            <List description="Topographical and contour survey. The candidate will be required to use ground methods to carry out a topographical survey covering at least 5Ha of a medium density area and done in accordance to the Boards guidelines to topographical surveys." />
          </div>
        </div>

        <div className="mt-5">
          <p>The candidate is notified of the following:</p>

          <div className="flex flex-col gap-2 justify-start items-start">
            <List description="That he/she should carry out the actual observations." />

            <List description="He keeps a diary of events for the assignments." />

            <List description="He develops a timetable of events which he shares with the Board and in addition notifies the Board of observation days and times at least two weeks in advance for supervision purposes." />

            <List description="That the whole assignment will last takes 10 weeks." />

            <List description="The candidate meets his/ her own costs including equipment." />
          </div>

          <p className="mt-4">The assignments can follow any order but the oral examinations shall be done after all the assignments have been submitted.</p>
        </div>
      </div>
    </div>
  )
}

export default TrialSurvey;