import React from 'react'

function Divider({ title }) {
    return (
        <span className="relative flex justify-center items-center">
            <div
                className="absolute inset-x-0 top-1/2 h-px -translate-y-1/2 bg-transparent bg-gradient-to-r from-transparent via-gray-500 to-transparent opacity-75"
            ></div>

            <span className="relative bg-white px-6 font-black leading-none text-gray-800 uppercase text-center text-divide">{title}</span>
        </span>
    )
}

export default Divider;