import React from 'react';
import "./Resources/resources.css";

function BlogCard({ resourceCategory, startYear, endYear, coverageContent, picBackground }) {
    return (
        <article className="relative overflow-hidden rounded-lg card-container">
            <img
                alt="Office"
                src={picBackground}
                className="absolute inset-0 w-full h-full object-cover card-image"
            />

            <div className="relative bg-gradient-to-t from-gray-900/50 to-gray-900/25 pt-32 sm:pt-48 lg:pt-64 card-content">
                <div className="p-4 sm:p-6">
                    <h5 className="block text-white"><strong>{startYear} - {endYear}</strong></h5>

                    <h1 className="text-white uppercase">{resourceCategory}</h1>

                    <h6 className="mt-1 text-white/95">
                        {coverageContent}
                    </h6>
                </div>
            </div>
        </article>
    )
}

export default BlogCard;