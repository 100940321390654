import React from 'react';
import Divider from "../divider";
import RegistrationPic from "./registration.jpg";

function Registration() {
    return (
        <div className="">
            <div className="registration">
                <img
                    className="h-96 w-full object-cover object-center"
                    src={RegistrationPic}
                    alt="Nature Scene"
                />
            </div>

            {/* becoming a member */}
            <div className="flex flex-col justify-center items-center my-14">
                <h2 className="text-3xl font-black leading-none text-gray-800 uppercase dark:text-white text-uppercase text-center">Fees Payable to Land Surveyors Board</h2>

                <div className="font-black leading-none text-gray-800 uppercase dark:text-white text-uppercase text-center mt-3">
                    <p rowSpan="2">LSB SERVICES</p>
                    <p colSpan="5">PAYMENTS TO THE BOARD THROUGH E-CITIZEN via MPESA PAYBILL</p>
                </div>

                <div className="flex justify-center items-center mt-3" style={{ width: "80vw" }}>
                    <table border="1" className="table table-striped border-t" style={{ width: '100%' }}>
                        <thead className="bg-gray-600 text-white">
                            <tr>
                                <th className="text-center">Service</th>
                                <th className="text-center">Business Number</th>
                                <th className="text-center">Account Number</th>
                                <th className="px-3">Service Code</th>
                                <th className="text-center">Amount (Ksh.)</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td className="px-3">Annual Renewal of Practicing License – Licensed Surveyor</td>
                                <td className="text-center">222222</td>
                                <td className="text-center">ARPL-<span className="text-red-500 fw-bold">XXX</span></td>
                                <td className="px-3"><span className="text-red-500 fw-bold">XXX</span> denotes License Number</td>
                                <td className="text-center">5,050.00</td>
                                <td rowSpan="7" className="text-start mx-2">All transaction MUST Include the KSH 50 E-Citizen Convenience fee</td>
                            </tr>

                            <tr>
                                <td className="px-3">Annual Renewal Fees – Approved Assistant</td>
                                <td className="text-center">"</td>
                                <td className="text-center">ANFA-<span className="text-red-500 fw-bold">XXX</span></td>
                                <td className="px-3"><span className="text-red-500 fw-bold">XXX</span> denotes License Number</td>
                                <td className="text-center">1,050.00</td>
                            </tr>

                            <tr>
                                <td className="px-3">Application for Kenya Survey License Registration</td>
                                <td className="text-center">"</td>
                                <td className="text-center">AKSL-<span className="text-red-500 fw-bold">YYY</span></td>
                                <td className="px-3"><span className="text-red-500 fw-bold">YYY</span> denotes Name of Applicant</td>
                                <td className="text-center">15,050.00</td>
                            </tr>

                            <tr>
                                <td className="px-3">Application for Registration of Approved Assistant</td>
                                <td className="text-center">"</td>
                                <td className="text-center">ARAA-<span className="text-red-500 fw-bold">XXX</span></td>
                                <td className="px-3"><span className="text-red-500 fw-bold">XXX</span> denotes License Number</td>
                                <td className="text-center">2,550.00</td>
                            </tr>

                            <tr>
                                <td className="px-3">Application for Land Law Examination</td>
                                <td className="text-center">"</td>
                                <td className="text-center">ALLE-<span className="text-red-500 fw-bold">YYY</span></td>
                                <td className="px-3"><span className="text-red-500 fw-bold">XXX</span> denotes Name of Applicant</td>
                                <td className="text-center">5,050.00</td>
                            </tr>

                            <tr>
                                <td className="px-3">Issue of Identity Cards</td>
                                <td className="text-center">"</td>
                                <td className="text-center">ISDC-<span className="text-red-500 fw-bold">XXX</span></td>
                                <td className="px-3"><span className="text-red-500 fw-bold">XXX</span> denotes License Number</td>
                                <td className="text-center">1,550.00</td>
                            </tr>

                            <tr>
                                <td className="px-3">Issue of Bound Land Law Papers</td>
                                <td className="text-center">"</td>
                                <td className="text-center">BLLP-<span className="text-red-500 fw-bold">YYY</span></td>
                                <td className="px-3"><span className="text-red-500 fw-bold">XXX</span> denotes Name of Applicant</td>
                                <td className="text-center">2,050.00</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="mt-5">
                <Divider />
            </div>
        </div>
    )
}

export default Registration;