import React from 'react';

const SuccessMessage = () => (
    <div className="text-center p-4 text-green-600 font-bold">
        <i className="fa-solid fa-circle-check fa-4x"></i>
        <h5 className='my-3'>Submission Successful!</h5>
        <p className='my2'>Your data has been submitted successfully.</p>
        <span>Redirects in [10 Seconds]</span>
    </div>
);

export default SuccessMessage;