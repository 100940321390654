import React from 'react';
import List from "../list";
import Workflow from '../WorkFlows/WorkFlow';

function SurveyorReg() {
  return (
    <div className="container">
      <h1 className="fw-bold mt-4">REQUIREMENTS FOR LAND SURVEY LICENCE</h1>
      <p>The following are currently what every surveyor pursuing a land survey license should equip himself/herself with: -</p>

      <div className="py-3 flex flex-col justify-center items-start">
        <List
          description="A farm Survey of at least ten (10) hectares with a section having a curvilinear boundary of not less than one hundred (100) meters in length. Note that the curvilinear section may be part of a different survey."
        />

        <List
          description="Cadastral Survey of at least thirty (30) plots either in one scheme or several schemes."
        />

        <List
          description="Topographical Survey of a minimum of five (5) hectares of a medium density area and done in accordance to the Board’s guidelines on topographical surveys."
        />

        <List
          description="Control Survey with at least four (4) new control points. This may be achieved within the farm survey mentioned in (i) above."
        />

        <List
          description="Duly completed declaration forms"
        />

        <p className="px-16 text-sm text-gray-500">
          <a href="/PRESCRIBED FORM A.doc" download>A</a> &amp; <a href="/License Application/PRESCRIBED FORM B-Declaration.doc" download>B</a> of the third schedule of the Survey Act
        </p>


        <List
          description="A pass in the Board's Land Law Examination."
        />

        <List
          description="Full membership of the Institution of Surveyors of Kenya, with a certificate of participation and up to date payment."
        />

        <List
          description="A duly completed application form of the Land Surveyors Board."
        />

        <List
          description="Application letter to the Board."
        />

        <List
          description="Application fees of KSh 15,000/="
        />
      </div>

      <div className="my-3">
        <small className="text-red-500 underline fw-bold mt-3">CAUTION: Ensure all the above requirements are available before you begin the process.</small>
      </div>

      <Workflow />
    </div>
  )
}

export default SurveyorReg;