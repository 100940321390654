import React, { useState, useEffect } from "react";
import "./Home.css";
import newsData from "./news.json";

const NewsCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const intervalDuration = 6000;

  useEffect(() => {
    const changeText = () => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % newsData.length);
    };

    const interval = setInterval(changeText, intervalDuration);
    return () => clearInterval(interval);
  }, [newsData.length, intervalDuration]);

  if (newsData.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className="text-container flex flex-col justify-center items-center my-16">
        <h4
          className={`text-gray-400 italic ${currentIndex !== 0 ? "fade-in" : ""
            }`}
        >
          {newsData[currentIndex].title}
        </h4>
        <p className="italic text-center">
          <i className="fa-solid fa-quote-left"></i> {newsData[currentIndex].content}{" "}
          <i className="fa-solid fa-quote-right"></i>
        </p>
      </div>
    </div>
  );
};

export default NewsCarousel;