import React from 'react'

function EventsReg() {
  return (
    <div className="container my-10">
      <h1 className="font-black leading-none text-gray-800 uppercase dark:text-white text-uppercase text-center my-10">registration for events</h1>

      <button className="button mt-4" type="submit">
        <span className="button_lg">
          <span className="button_sl"></span>
          <span className="button_text">apply</span>
        </span>
      </button>
    </div>
  )
}

export default EventsReg;