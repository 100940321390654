import React, { useState, useEffect } from "react";
function CurrentRegister() {
  const [assistants, setAssistants] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredAssistants, setFilteredAssistants] = useState([]);

  useEffect(() => {
    // Fetch data from the server
    fetch("https://portal.lsb.go.ke/account/approved/")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setAssistants(data);
        setFilteredAssistants(data); // Initialize filtered data
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  // Update filteredAssistants based on searchQuery
  useEffect(() => {
    setFilteredAssistants(
      assistants.filter((assistant) =>
        assistant.assistant_id
          ?.toString()
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery, assistants]);

  return (
    <div className="w-full">
      {/* Search input */}
      <div className="relative overflow-x-auto">
        <label
          htmlFor="approvedAssistants"
          className="relative block overflow-hidden border-b border-gray-200 bg-transparent pt-3 focus-within:border-blue-600"
        >
          <input
            type="text"
            placeholder=""
            className="peer h-12 w-full border-none bg-gray-200 px-4 placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 rounded-t-lg fw-bold"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <span className="absolute start-0 top-2 -translate-y-1/2 text-xs text-gray-800 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-2 peer-focus:text-xs px-4">
            Search by Assistant ID
          </span>
        </label>
      </div>

      {/* Table */}
      <table className="w-full text-sm text-left rtl:text-right table table-striped mt-2">
        <thead>
          <tr className="bg-white border-b">
            <th className="py-1 px-3 bg-black text-white">APPROVED ASST.</th>
            <th className="py-1 px-3 bg-black text-white">ASSISTANT No.</th>
            <th className="py-1 bg-black text-white">SUPERVISED BY</th>
            <th className="py-1 bg-black text-white">SUPERVISOR LICENSE</th>
          </tr>
        </thead>

        <tbody>
          {filteredAssistants.length > 0 ? (
            filteredAssistants.map((row, index) => (
              <tr key={index}>
                <td>{row.assistant_name}</td>
                <td>{row.assistant_no}</td>
                <td>{row.supervisor_name}</td>
                <td>{row.supervisor_license}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4">No assistants found.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default CurrentRegister;