import React from 'react';

function PrivacyPolicy() {
    return (
        <div className='my-3'>
            <div className='container my-5'>
                <h1 className='flex flex-col font-black leading-none text-gray-800 uppercase'>LSB Privacy Policy</h1>
            </div>

            <div className='container flex flex-col justify-start items-start'>
                <div className='my-2'>
                    <h5 className='mb-3 fw-bold uppercase'>1. Introduction</h5>
                    <p>Welcome to the Kenya Land Surveyors Board website. By accessing and using this website, you agree to comply with the following terms and conditions. If you do not agree with any part of these terms, please refrain from using the website.</p>
                </div>

                <div className='my-2'>
                    <h5 className='mb-3 fw-bold uppercase'>2. DATA PROTECTION AND PRIVACY</h5>

                    <div className='ml-5'>
                        <h6 className='fw-bold'>2.1 Collection and Use of Personal Data</h6>
                        <ul style={{ listStyleType: 'square' }}>
                            <li>The Kenya Land Surveyors Board collects and processes personal data in accordance with the Data Protection Act of 2019.</li>
                            <li>Personal data collected may include names, contact information, and other relevant details.</li>
                            <li>The purpose of collecting personal data is to facilitate communication, provide services, and comply with legal requirements.</li>
                        </ul>
                    </div>

                    <div className='ml-5'>
                        <h6 className='fw-bold'>2.2 Data Security</h6>
                        <ul style={{ listStyleType: 'square' }}>
                            <li>The Kenya Land Surveyors Board takes reasonable measures to protect personal data from unauthorized access, loss, or misuse.</li>
                            <li>Users are responsible for maintaining the confidentiality of their login credentials.</li>
                        </ul>
                    </div>

                    <div className='ml-5'>
                        <h6 className='fw-bold'>2.3 Cookies and Tracking</h6>

                        <ul style={{ listStyleType: 'square' }}>
                            <li>This website may use cookies for analytics and user experience improvement.</li>
                            <li>By using the website, you consent to the use of cookies.</li>
                        </ul>
                    </div>
                </div>

                <div className='my-2'>
                    <h5 className='mb-3 fw-bold uppercase'>3. Intellectual Property</h5>

                    <ul style={{ listStyleType: 'square' }} className='ml-5'>
                        <li>All content on the Kenya Land Surveyors Board website, including text, land law papers, guidelines, images, and logos, is protected by intellectual property laws.</li>
                        <li>Users may not reproduce, distribute, or modify any content without prior authorization.</li>
                    </ul>
                </div>

                <div className='my-2'>
                    <h5 className='mb-3 fw-bold uppercase'>4. links to external websites</h5>

                    <ul style={{ listStyleType: 'square' }} className='ml-5'>
                        <li>The website may contain links to external websites. The Kenya Land Surveyors Board is not responsible for the content or privacy practices of these sites.</li>
                    </ul>
                </div>

                <div className='my-2'>
                    <h5 className='mb-3 fw-bold uppercase'>5. disclaimer</h5>

                    <ul style={{ listStyleType: 'square' }} className='ml-5'>
                        <li>The information provided on this website is for general purposes only. The Kenya Land Surveyors Board does not guarantee its accuracy or completeness.</li>
                        <li>Users should seek professional advice before relying on any information provided.</li>
                    </ul>
                </div>

                <div className='my-2'>
                    <h5 className='mb-3 fw-bold uppercase'>6. ammendments</h5>

                    <ul style={{ listStyleType: 'square' }} className='ml-5'>
                        <li>The Kenya Land Surveyors Board reserves the right to update or modify these terms and conditions at any time.</li>
                        <li>Users are encouraged to review the terms periodically.</li>
                    </ul>
                </div>

                <div className='my-2'>
                    <h5 className='mb-3 fw-bold uppercase'>7. contact us</h5>
                    <p>If you need to inquire about any aspect of this Privacy Policy or have concerns or requests related to our privacy practices, please feel free to reach out to us:</p>

                    <div className='flex flex-col items-center text-center'>
                        <p><span className='fw-bold'>inquiry@lsb.go.ke</span> <br /> THE LAND SURVEYORS BOARD <br /> P.O BOX 30046-00200 <br /> Survey of Kenya Headquarters <br /> Ruaraka, Nairobi Kenya</p>
                    </div>

                    <p>By utilizing our website or sharing your personal information with us, you affirm that you have comprehensively read and grasped this Privacy Policy. Furthermore, you grant consent for the collection, utilization, and disclosure of your personal information as outlined in this policy.</p>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy;