import React, { useState } from 'react';

function Tab({ tabs }) {
  const [activeTab, setActiveTab] = useState(tabs[0].id);

  return (
    <div className="w-90vw"> {/* Changed max-w-lg to w-80vw */}
      <div className="flex border-b border-gray-200">
        {tabs.map(tab => (
          <button
            key={tab.id}
            onClick={() => setActiveTab(tab.id)}
            className={`${
              activeTab === tab.id
                ? 'border-blue-500 text-blue-600'
                : 'border-transparent text-gray-500 hover:text-gray-700'
            } flex-1 inline-flex justify-center py-4 px-6 border-b-2 font-bold text-1xl lg:text-2xl`}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div className="p-7" style={{ width: '95vw' }}> {/* Set a fixed width */}
        {tabs.map(tab => (
          <div
            key={tab.id}
            className={`${activeTab !== tab.id && 'hidden'}`}
          >
            {tab.content}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Tab;
