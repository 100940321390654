import './dashboard.css';
import React, { useState } from 'react';
import USER from './user.svg';

function LoggedProfile() {
    const [userSettings, setUserSettings] = useState(false);

    const toggleUserSettings = () => {
        setUserSettings(!userSettings);
    };

    return (
        <div className="container-fluid flex flex-col gap-4 bg-gray-200">
            <div className="flex flex-col my-2 px-4 relative">
                {/* main nav - user */}
                <div className="inline-flex gap-2 items-center overflow-hidden">
                    <img src={USER} className='w-12 h-12' alt='UserProfile' id='UserProfile' />

                    <button
                        className="h-full p-2"
                        onClick={toggleUserSettings}
                    >
                        {userSettings ? (
                            <i className="fa-solid fa-chevron-up"></i>
                        ) : (
                            <i className="fa-solid fa-chevron-down"></i>
                        )}
                    </button>

                    {/* Menu settings */}
                    {userSettings && (
                        <div className="absolute z-10 mt-52 left-0 w-36 divide-y divide-gray-100 rounded-md border border-gray-100 bg-white shadow-lg">
                            <div className="p-2">
                                <a
                                    href="/"
                                    className="block rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-gray-100 hover:text-gray-700 fw-bold text-decoration-none"
                                    role="menuitem"
                                >
                                    Profile
                                </a>

                                <a
                                    href="/"
                                    className="block rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-gray-100 hover:text-gray-700 fw-bold text-decoration-none"
                                    role="menuitem"
                                >
                                    Settings
                                </a>
                            </div>

                            <div className="p-2">
                                <form method="POST" action="/">
                                    <button
                                        type="submit"
                                        className="flex w-full items-center gap-2 rounded-lg px-4 py-2 text-sm text-red-700 fw-bold hover:bg-red-100"
                                        role="menuitem"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-4 w-4"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                            />
                                        </svg>
                                        LogOut
                                    </button>
                                </form>
                            </div>
                        </div>
                    )}
                </div>

                {/* main page content */}
                <div className="grid grid-cols-2 gap-4 lg:grid-cols-4 my-8 container">
                    <div className="flex flex-col gap-2 justify-center items-center bg-white rounded-2 p-2">
                        <h3 className='text-5xl fw-bold'>16</h3>
                        <p className='fw-bold'>Asstistants</p>
                    </div>

                    <div className="flex flex-col gap-2 justify-center items-center bg-white rounded-2 p-2">
                        <h3 className='text-blue-600 text-5xl fw-bold'>15</h3>
                        <p className='fw-bold'>Submitted Jobs</p>
                    </div>

                    <div className="flex flex-col gap-2 justify-center items-center bg-white rounded-2 p-2">
                        <h3 className='text-red-600 text-5xl fw-bold'>5</h3>
                        <p className='fw-bold'>Pending Jobs</p>
                    </div>

                    <div className="flex flex-col gap-2 justify-center items-center bg-white rounded-2 p-2">
                        <h3 className='text-green-600 text-5xl fw-bold'>10</h3>
                        <p className='fw-bold'>Complete Jobs</p>
                    </div>
                </div>

                {/* assistants table */}
                <div className='container-fluid'>
                    <div className='grid grid-cols-1 gap-3 lg:grid-cols-2 lg:gap-8'>
                        <div>
                            <h1 className='fw-bold underline underline-offset-8'>Approved Assistants</h1>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Full Name</th>
                                        <th scope="col">Asst. Number</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">1</th>
                                        <td>Mark</td>
                                        <td>12/24</td>
                                        <td>Active</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">2</th>
                                        <td>Jacob</td>
                                        <td>13/24</td>
                                        <td>Inactive</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div>
                            <h1 className='fw-bold underline underline-offset-8'>Submitted Jobs</h1>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Job Name</th>
                                        <th scope="col">Job Number</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">1</th>
                                        <td>Embakasi Ranch</td>
                                        <td>12/24</td>
                                        <td style={{ color: "#228B22", fontWeight:"700" }}>Complete</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">2</th>
                                        <td>Ruiru Ranch</td>
                                        <td>13/24</td>
                                        <td style={{ color: "#ff0000", fontWeight:"700" }}>Pending</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoggedProfile;