import React from "react";
import List from "../list";

function AnnualLicence() {
  return (
    <div className="container my-10">
      <h1 className="font-black leading-none text-gray-800 uppercase dark:text-white text-uppercase text-center my-10">
        Procedure for the Practicing License Renewals
      </h1>

      <div className="flex flex-col items-start">
        <List description="Visit the online portal for Practicing License Renewals" />

        <List description="Log in or (create an account if you are a new user with assistance from LSB Secretariat)" />

        <List description="Upload the following documents:" />

        <ul className="mx-12" style={{ listStyleType: "square" }}>
          <li className="text-sm text-gray-500">
            Certificate of Good Standing for both the Licensed Surveyors and
            Approved Assistant(s)
          </li>
          <li className="text-sm text-gray-500">
            Confirm List of Approved Assistant(s) as displayed in the portal
          </li>
        </ul>

        <List description="Review the information provided to ensure accuracy" />

        <List description="Complete payment through E-Citizen as prompted" />

        <List description="Submit the application to LSB for verification" />

        <List description="Download your Annual Practicing License in Portal" />
      </div>

      <button className="button mt-4" type="submit">
        <span className="button_lg">
          <span className="button_sl"></span>
          <span className="button_text">renew license</span>
        </span>
      </button>
    </div>
  );
}

export default AnnualLicence;
