import React from 'react';
import List from "../list";
import CodeEthics from './Licensed Surveyors Code of Professional Conduct, 1997.pdf';


function EngageSurv() {
    return (
        <div className="container my-10">
            <h1 className="font-black leading-none text-gray-800 uppercase dark:text-white text-uppercase text-center my-6">why use a licensed surveyor</h1>

            <p className="mb-6">
                In Kenya, surveying is crucial for managing land, facilitating development, and safeguarding property rights. The Survey Act CAP 299 governs surveying practices in the country, with oversight from the Land Surveyors Board.
            </p>

            <div className="mb-6">
                <h4 className="font-bebas-neue leading-none my-4">Licensing Process:</h4>
                <p>
                    Under the Survey Act Cap 299, a person seeking to practice as a licensed surveyor must obtain a license from the Land Surveyors Board. The licensing process typically involves meeting specific educational qualifications, practical experience, and passing examinations set by the Land Surveyors Board. This rigorous procedure ensures that licensed surveyors possess the requisite knowledge and skills to carry out their duties effectively.
                </p>
            </div>

            <div className="mb-6">
                <h4 className="font-bebas-neue leading-none my-4">Technical Proficiency:</h4>
                <p>
                    Licensed surveyors possess a strong technical background in surveying, mathematics, geodesy, spatial data analytics, remote sensing, and related sciences. They are proficient in using surveying instruments; GPS/GNSS receivers, Total Stations, Unmanned Aerial Vehicles, Laser Scanners, GIS software, remote sensing tools, and other geospatial technologies.
                </p>
            </div>


            <div className="mb-6">
                <h4 className="font-bebas-neue leading-none my-4">Responsibilities of a Licensed Surveyor:</h4>
                <p>Licensed surveyors have significant responsibilities including but not limited to:</p>

                <div className="flex flex-col items-start">
                    <List description="Boundary Determination and Demarcation:" />
                    <ul className="mx-12" style={{ listStyleType: 'square' }}>
                        <li className="text-sm text-gray-500">Licensed surveyors bear the responsibility of precisely determining and marking property boundaries. This task involves conducting surveys to establish the exact location of boundaries according to legal descriptions and survey plans.</li>
                    </ul>

                    <List description="Preparation of Survey Plans:" />
                    <ul className="mx-12" style={{ listStyleType: 'square' }}>
                        <li className="text-sm text-gray-500">Licensed Surveyors are tasked with creating detailed survey plans that accurately represent the boundaries, dimensions, and features of surveyed land parcels. These plans serve as legal documents and are presented to the Director of Surveys for registration and approval.</li>
                    </ul>

                    <List description="Cadastral Surveying:" />
                    <ul className="mx-12" style={{ listStyleType: 'square' }}>
                        <li className="text-sm text-gray-500">Licensed surveyors engage in cadastral surveys to develop and maintain cadastral maps and records. These surveys entail accurately defining land parcels, documenting ownership details, and updating a comprehensive cadastral database for land administration purposes.</li>
                    </ul>

                    <List description="Sectional Property Survey:" />
                    <ul className="mx-12" style={{ listStyleType: 'square' }}>
                        <li className="text-sm text-gray-500">Licensed surveyor’s role is pivotal in creating accurate sectional plans that define individual units within a building, allowing for clear ownership and management under the Sectional Property Act of 2020.</li>
                    </ul>

                    <List description="Topographic Surveying:" />
                    <ul className="mx-12" style={{ listStyleType: 'square' }}>
                        <li className="text-sm text-gray-500">Surveyors are responsible for conducting topographic surveys to map both natural and artificial features within a specific area. These surveys offer valuable insights for urban planning, infrastructure development, and environmental management.</li>
                    </ul>

                    <List description="Geodetic Surveying:" />
                    <ul className="mx-12" style={{ listStyleType: 'square' }}>
                        <li className="text-sm text-gray-500">Licensed surveyors undertake geodetic surveys to establish precise reference frameworks for spatial data positioning. These surveys are vital for various applications such as navigation and precise positioning.</li>
                    </ul>

                    <List description="Boundary Dispute Resolution:" />
                    <ul className="mx-12" style={{ listStyleType: 'square' }}>
                        <li className="text-sm text-gray-500">Surveyors may be called upon to resolve disputes over property boundaries or conflicting land rights among neighboring landowners. They utilize their expertise in land surveying and interpretation of survey data to provide impartial assessments and recommendations for resolution.</li>
                    </ul>

                    <List description="Expert Witness:" />
                    <ul className="mx-12" style={{ listStyleType: 'square' }}>
                        <li className="text-sm text-gray-500">In legal proceedings concerning land disputes or boundary issues, licensed surveyors may be requested to provide expert testimony and evidence based on their survey findings and professional expertise.</li>
                    </ul>

                    <List description="Consultation and Advisory Services:" />
                    <ul className="mx-12" style={{ listStyleType: 'square' }}>
                        <li className="text-sm text-gray-500">Licensed surveyors often provide expert advice and consultation on matters related to land surveying and land tenure systems to individuals, organizations, and government agencies.</li>
                    </ul>

                    <List description="Engineering Surveys:" />
                    <ul className="mx-12" style={{ listStyleType: 'square' }}>
                        <li className="text-sm text-gray-500">Licensed surveyors in engineering surveying are tasked with conducting precise measurements, assessments, and analyses of land and structures to support engineering projects. Their responsibilities include establishing accurate control points, monitoring infrastructure, setting out design features, and providing spatial data for infrastructure planning. They play a vital role in ensuring construction activities adhere to engineering plans and specifications, contributing to the safety, efficiency, and sustainability of engineering projects.</li>
                    </ul>
                </div>
            </div>

            <div>
                <h4 className="leading-none my-4">Ethical Considerations</h4>
                <p>
                    Licensed surveyors adhere to a strict code of ethics, prioritizing professionalism, integrity, and impartiality. They serve the public interest and maintain the integrity of surveying practice above personal interests. Refer to the Land Surveyors Licensed surveyors adhere to a strict code of ethics, prioritizing professionalism, integrity, and impartiality. They serve the public interest and maintain the integrity of surveying practice above personal interests. Refer to the Land Surveyors <a href={CodeEthics} rel='noreferrer' target='_blank' className="text-decoration-underline text-blue-600">Code of Ethics</a>.
                </p>

                <p>
                    Licensed surveyors play a crucial role regulated by the Survey Act Cap 299 of the laws of Kenya and overseen by the Land Surveyors’ Board. Their expertise contributes to sustainable land management, protects property rights, and advances development initiatives, ensuring the accuracy, consistency and fairness of surveying practices across the country
                </p>
            </div>
        </div>
    )
}

export default EngageSurv;