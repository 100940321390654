import React, { useState } from 'react';
import InquiryPic from "./inquiries.jpg";

function Inquiries() {
    const [uploadedDocuments, setUploadedDocuments] = useState([]);
    const [uploadError, setUploadError] = useState("");

    // Function to handle file selection
    const handleFileSelect = (e) => {
        const files = e.target.files;
        const allowedTypes = ['application/pdf', 'image/jpeg', 'image/png', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
        const selectedFiles = Array.from(files);
        const invalidFiles = selectedFiles.filter(file => !allowedTypes.includes(file.type));

        if (invalidFiles.length > 0) {
            setUploadError('Invalid file type. Please upload a DOCX, PNG, JPG, or PDF file.');
            e.target.value = ''; // Clear the file input
        } else {
            setUploadedDocuments([...uploadedDocuments, ...selectedFiles]);
            setUploadError('');
        }
    };

    // Function to remove uploaded file
    const removeFile = (index) => {
        const updatedFiles = [...uploadedDocuments];
        updatedFiles.splice(index, 1);
        setUploadedDocuments(updatedFiles);
    };

    // Function to handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();

        // Perform custom validation
        const form = e.target;
        const requiredFields = form.querySelectorAll('[required]');
        let isValid = true;

        requiredFields.forEach(field => {
            if (!field.value) {
                isValid = false;
                field.classList.add('border', 'border-red-500');
            } else {
                field.classList.remove('border', 'border-red-500');
            }
        });

        if (isValid) {
            form.submit();
        } else {
            console.log('Please fill in all required fields.');
        }
    };

    return (
        <div className="">
            <div className="hero">
                <img
                    className="h-96 w-full object-cover object-center"
                    src={InquiryPic}
                    alt="Nature Scene"
                />
            </div>

            <div className="container mt-4">
                <div className="container mt-2">
                    <form className="mt-4 p-3" onSubmit={handleSubmit}>
                        <div className="border-t text-center">
                            <h1 className="flex flex-col font-black leading-none text-gray-800 uppercase dark:text-white">
                                Inquiry Form
                            </h1>

                            <p>Submit your inquiry and we will get in touch shortly</p>
                        </div>

                        <div className="mt-3">
                            <p className="fw-bold">Personal Details</p>

                            <div class="row">
                                <div class="col">
                                    <input type="text" class="form-control" placeholder="First name" aria-label="First name" required />
                                </div>
                                <div class="col">
                                    <input type="text" class="form-control" placeholder="Last name" aria-label="Last name" required />
                                </div>
                            </div>
                        </div>

                        <div className="mt-3">
                            <p className="fw-bold">Contact Details</p>
                            <div class="row">
                                <div class="col">
                                    <input type="text" class="form-control" placeholder="Phone Number" aria-label="Phone Number" />
                                </div>
                                <div class="col">
                                    <input type="text" class="form-control" placeholder="Email Address" aria-label="Email Address" required />
                                </div>
                            </div>
                        </div>

                        <div className="mt-3">
                            <p className="fw-bold">Your Message</p>
                            <div class="col">
                                <textarea class="form-control" id="exampleFormControlTextarea1" placeholder="Inquiry Message" rows="4" required />
                            </div>
                        </div>

                        <div>
                            <div className="mt-2">
                                <label htmlFor="dropzone-file" className="text-red-800 uppercase fw-bold text-center block">SUPPORTING EVIDENCE *</label>
                                <div className="flex items-center justify-center w-full">
                                    <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                            <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                                            </svg>
                                            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                                            <p className="text-xs text-gray-500 dark:text-gray-400">DOCX, PNG, JPG, or PDF</p>
                                            <small className="text-gray-500 dark:text-gray-400">Ensure any image uploaded is clear and indicates your complaint & maximum of 5 Files</small>
                                        </div>
                                        <input id="dropzone-file" type="file" className="hidden" accept=".docx,.png,.jpg,.pdf" onChange={handleFileSelect} multiple />
                                    </label>
                                </div>
                            </div>

                            {uploadError && (
                                <p className="text-red-500 text-sm mt-2">{uploadError}</p>
                            )}
                            {uploadedDocuments.length > 0 && (
                                <ul className="mt-2">
                                    <small className="underline fw-bold">Uploaded Documents:</small>
                                    {uploadedDocuments.map((file, index) => (
                                        <li key={index}>
                                            {file.name}
                                            <button onClick={() => removeFile(index)} className="mx-2 text-red-600 fw-bold">Remove</button>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>

                        {/* Form submission button */}
                        <button className="button mt-4">
                            <span className="button_lg">
                                <span className="button_sl"></span>
                                <span className="button_text">submit</span>
                            </span>
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Inquiries;
