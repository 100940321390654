import React from "react";
import "./NavItems.css";
import Surveyor from "./surveyor.svg";
import Avatar from "./public.svg";
import Graduates from "./student.svg";

const NavItems = () => {
  return (
    <div className="hoverDropdown">
      <div className="dropdown">
        <div className="dropbtn">
          <img src={Avatar} alt="consumers" className="w-8"/>
          <p className="text-img">FOR PUBLIC</p>
        </div>

        <div className="dropdown-content">
          <a href="/titlesurv" className="pt-2">USE OF TITLE LICENSED SURVEYOR</a>
          <a href="/engagesurv" className="pt-2">WHY ENGAGE A LICENSED SURVEYOR</a>
          <a href="/register" className="pt-2">FIND A LICENSED SURVEYOR</a>
          <a href="/compliments" className="pt-2">COMPLIMENT A SURVEYOR</a>
          <a href="/inquiries" className="pt-2">MAKE AN INQUIRY</a>
          <a href="/complaints" className="pt-2">SUBMIT A COMPLAINT</a>
          <a href="/report" className="pt-2">REPORT AN OFFENSE</a>
        </div>
      </div>

      <div className="dropdown">
        <div className="dropbtn">
          <img src={Surveyor} alt="surveyors"  className="w-8"/>
          <p className="text-img">FOR LICENSED SURVEYORS</p>
        </div>

        <div className="dropdown-content">
          <a href="/annuallicence" className="pt-2">PRACTICING CERTIFICATE RENEWALS</a>
          {/* <a href="/continuingDev" className="pt-2">CONTINUING PROFESSIONAL DEVELOPMENT</a> */}
          <a href="/resources" className="pt-2">GUIDELINES</a>
        </div>
      </div>

      <div className="dropdown">
        <div className="dropbtn">
          <img src={Graduates} alt="graduates"  className="w-8"/>
          <p className="text-img">FOR GRADUATES</p>
        </div>

        <div className="dropdown-content">
          <a href="/surveyorreg" className="pt-2">LICENSE APPLICATION CHECKLIST</a>
          <a href="/trialSurvey" className="pt-2">TRIAL SURVEY GUIDELINES</a>
          <a href="/approvedreg" className="pt-2">APPROVED ASSISTANTS APPLICATION GUIDELINES</a>
          <a href="/landlaw" className="pt-2">APPLICATION TO SIT LAND LAW EXAMINATION</a>
          <a href="/resources" className="pt-2">LAND LAW EXAMINATION PAST PAPERS</a>
          <a href="/resources" className="pt-2">LAND LAW EXAMINATION TIMETABLE</a>
        </div>
      </div>
    </div>
  );
};

export default NavItems;
