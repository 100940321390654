import React from 'react'

function TitleSurv() {
    return (
        <div className="container my-10">
            <h1 className="font-black leading-none text-gray-800 uppercase dark:text-white text-uppercase text-center my-10">use of a title licensed surveyor</h1>

            <p className="text-center">
                Under the Survey Act CAP 299, it is considered a violation for an individual who is not duly registered or licensed by the Kenya Land Surveyors’ Board to use the title 'Licensed Surveyor' or purport to engage in the practice of land surveying.
            </p>
        </div>
    )
}

export default TitleSurv;