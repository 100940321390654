import React from 'react';

function List({ requirement, description }) {
    return (
        <div className="flex justify-center items-center px-6 md:px-10">
            <div className="space-y-6 border-l-2 border-dashed">
                <div className="relative flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="absolute -ml-3.5 h-7 w-7 rounded-full text-blue-500">
                        <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clipRule="evenodd" />
                    </svg>
                    <div className="ml-6">
                        <h5 className="font-bold text-blue-500">{requirement}</h5>
                        <p className="mt-2 text-gray-500">{description}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default List;